"use strict";

(($, win, doc) => {    // JQ ES6 sw Pack 21
  
  const $win = $(win),
    $doc = $(doc),
    $html = $('html'),
    $body = $('body');
  
  /* plugin Type BlockLink */
  $.fn.blocklinkAssy = function () { // bind under this
    $(this).on("click", function (e) {
      e.preventDefault();
      win.open($(this).find("a").attr("href"), $(this).find("a").attr("target") || "_self");
    }).css({cursor: "pointer"});
  };
  
  
  const checkMediaQuery = ()=> {
    // https://zenn.dev/yuki0410/articles/878f4afbff6668d4e28a-2
    // window.matchMedia をちゃんと使った方法
    const mediaQueryList = window.matchMedia("(max-width:767px)");
    /**
     * イベントリスナー
     */
    const listener = (event) => {
      if (event.matches) {
        console.log('768px未満');
      } else {
        console.log('768px以上');
      }
    };
    mediaQueryList.addEventListener("change", listener);
    listener(mediaQueryList);
  };
  
  
  // do it DRF
  $(() => {
    // const's
    checkMediaQuery();
   
    /* BlockLink Assy */
    $(".hNavi").blocklinkAssy();
  
    /**
     * smooth scr
     * todo assy.jsかこっちかで。
     */
    $('a[href^="#"]').on("click",function(){
      const adjust = 0;
      const speed = 321;
      let href= $(this).attr("href");
      let target = $(href === "#" || href === "" ? 'html' : href);
      let position = target.offset().top + adjust;
      $('body,html').animate({scrollTop:position}, speed);
      return false;
    });
   
    // from bootstrap offcanvas.js
    $('[data-toggle="offcanvas"]').on('click', function () {
      $('.offcanvas-collapse').toggleClass('open')
    })
    $('.dropdown-toggle').on('click', function () {
      $(this).parent().toggleClass('show')
      $(this).parent().find('.dropdown-menu').toggleClass('show')
    })
    
    
  }); // DRF }
  
  /* .es6 (load) */
  $win.on('load scroll', function() {
  });
 
})(jQuery, window, document);

// swua
"use strict";!function(){window._uac={};var e=document.documentElement,i=window.navigator.userAgent.toLowerCase(),d=window.navigator.appVersion.toLowerCase();_uac.browser=-1!==i.indexOf("edge")?"edge":-1!==i.indexOf("iemobile")?"iemobile":-1!==i.indexOf("trident/7")?"ie11":-1===i.indexOf("msie")||-1!==i.indexOf("opera")?-1!==i.indexOf("chrome")&&-1===i.indexOf("edge")?"chrome":-1!==i.indexOf("safari")&&-1===i.indexOf("chrome")?"safari":-1!==i.indexOf("opera")?"opera":-1!==i.indexOf("firefox")?"firefox":"unknown_browser":-1!==d.indexOf("msie 6.")?"ie6":-1!==d.indexOf("msie 7.")?"ie7":-1!==d.indexOf("msie 8.")?"ie8":-1!==d.indexOf("msie 9.")?"ie9":-1!==d.indexOf("msie 10.")?"ie10":void 0,_uac.device=-1!==i.indexOf("iphone")||-1!==i.indexOf("ipod")?"iphone":-1!==i.indexOf("ipad")?"ipad":-1!==i.indexOf("android")?"android":-1!==i.indexOf("windows")&&-1!==i.indexOf("phone")?"windows_phone":"",_uac.iosVer=function(){if(/iP(hone|od|ad)/.test(navigator.platform)){var e=navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);return[parseInt(e[1],10),parseInt(e[2],10),parseInt(e[3]||0,10)][0]}return 0}(),_uac.isiOS="iphone"===_uac.device||"iPad"===_uac.device,_uac.isMobile=-1!==i.indexOf("mobi")||"iphone"===_uac.device||"windows_phone"===_uac.device&&-1===i.indexOf("wpdesktop")||"iemobile"===_uac.device,_uac.isTablet="ipad"===_uac.device||"android"===_uac.device&&!_uac.isMobile,_uac.isTouch="ontouchstart"in window,_uac.isModern=!("ie6"===_uac.browser||"ie7"===_uac.browser||"ie8"===_uac.browser||"ie9"===_uac.browser||0<_uac.iosVer&&_uac.iosVer<8),_uac.addClass=function(){_uac.browser&&e.classList.add(_uac.browser),_uac.device&&e.classList.add(_uac.device),e.classList.add(_uac.isMobile?"mobile":"desktop"),e.classList.add(_uac.isTouch?"touch":"mouse"),e.classList.add(_uac.isModern?"modern":"old"),_uac.isTablet&&e.classList.add("tablet")},document.addEventListener("DOMContentLoaded",function(){_uac.addClass()})}();
